<template>
  <section
    id="tutoringContent"
    @click="
      () => {
        isB2BInfoTooltipClicked = false;
      }
    "
  >
    <div v-if="isApplySubmitDoneModalOpen" class="common-modal common-modal--dimmed" style="display: block">
      <div class="common-modal__modal">
        <div class="common-modal__content">1:1 무료 수업이 신청 완료되었습니다.</div>

        <div class="common-modal__buttonWrap">
          <button type="button" class="common-modal__button" @click="closeApplySubmitDoneModal">확인</button>
        </div>
      </div>
    </div>
    <div v-if="isApplySubmitFailModalOpen" class="common-modal common-modal--dimmed" style="display: block">
      <div class="common-modal__modal">
        <div class="common-modal__content">인증이 실패하였습니다</div>

        <div class="common-modal__buttonWrap">
          <button type="button" class="common-modal__button" @click="closeApplySubmitFailModal">확인</button>
        </div>
      </div>
    </div>
    <div class="inner">
      <header id="classApplyHeader">
        원어민과 1:1 무료 수업으로 레벨 진단을 받고 전문 컨설턴트의 맞춤 학습 코칭도 받아보세요.
      </header>

      <p class="classApply-header-desc">영어 회화, 어디서부터 시작할지 고민이라면? 링고라가 도와드릴게요!</p>

      <article id="description">
        <div class="description__card__list">
          <div class="description__card" v-for="desc in descList" :key="desc.id">
            <img
              class="description__card__icon--check"
              src="https://cdn.tutoring.co.kr/uploads/home/img/leveltest/ic_chk_applylt.png"
            />
            <div class="description__card__desc" v-html="desc.text"></div>
          </div>
        </div>
      </article>
      <article id="checkLevel">
        <label class="checkLevel__label">어떤 목적으로 영어 회화를 배우려고 하시나요?</label>
        <div class="checkLevel__levels">
          <button
            v-for="purpose in purposeList"
            :key="purpose.id"
            :class="[{ checked: purpose.checked }]"
            @click="onClickPurposeBtn(purpose.id)"
          >
            {{ purpose.name }}
          </button>
        </div>
      </article>
      <article id="applierInfo">
        <label class="applierInfo__label">무료 원어민 1:1 회화 첫 수업 신청을 위해 신청자 정보를 입력해 주세요.</label>
        <div class="applierInfo__b2b__info__wrapper">
          <p class="applierInfo__b2b__info">단체 수강(사내 교육, 복지몰, 학교 단체수강 등) 예정이신가요?</p>
          <img
            class="applierInfo__b2b__info__icon"
            @click="handleB2bInfoTooltipClick"
            @click.stop
            src="https://res.tutoring.co.kr/res/images/v2/classApply/icon_alert_circle.svg"
            alt="alert_circle_icon"
            @mouseover="() => (isB2bInfoTooltipOpen = true)"
            @mouseleave="() => (isB2bInfoTooltipOpen = false)"
          />

          <ul v-if="isB2bInfoTooltipOpen || isB2BInfoTooltipClicked" class="tooltip" ref="tooltip">
            <img class="triangle" src="https://res.tutoring.co.kr/res/images/v2/classApply/Tip.svg" alt="" />
            <li>
              단체 수강(사내 교육, 복지몰, 학교 단체수강 등) 예정인 경우, 각 교육 담당 부서의 안내에 따라 수강해 주세요.
            </li>
            <li>개인이 회사 지원금으로 수강 예정인 경우, 무료 첫 수업 신청이 가능합니다.</li>
            <li>
              기타 기업 제휴 문의는
              <span @click="moveToB2bProgram">여기</span>
              를 클릭해 주세요.
            </li>
          </ul>
        </div>
        <div class="applierInfo__form">
          <div class="applierInfo__form__block">
            <div class="input__wrapper">
              <Input
                :value="name"
                :placeholder="'이름'"
                @input="onChangeName"
                @blur="onBlurNameInput"
                :warn="nameWarn"
                @focus="onFocusNameInput"
              />
              <div class="input__message--warn" v-if="nameWarn">{{ nameWarn }}</div>
            </div>
          </div>
          <div class="applierInfo__form__block">
            <div class="input__wrapper">
              <Input
                :value="phone"
                :placeholder="'휴대폰 번호(숫자만 입력)'"
                @input="onChangePhone"
                :onlyNumber="true"
                :option="{ max: 11 }"
                @blur="onBlurPhoneInput"
                :warn="phoneWarn"
                @focus="onFocusPhoneInput"
                :disabled="authDone"
              />
              <div class="input__message--warn" v-if="phoneWarn">{{ phoneWarn }}</div>
            </div>
            <button
              class="requestAuthBtn"
              :class="{ disabled: isAuthBtnDisabled || authDone }"
              @click="onClickRequestAuthBtn"
              :disabled="isAuthBtnDisabled || authDone"
            >
              {{ isRetryAuthBtn ? '재요청' : '인증번호 받기' }}
            </button>
          </div>
          <div class="applierInfo__form__block">
            <div class="input__wrapper">
              <Input
                :value="auth"
                :placeholder="'인증번호 입력'"
                @input="onChangeAuth"
                :onlyNumber="true"
                :option="{ max: 4 }"
                @blur="onBlurAuthInput"
                :disabled="authDone"
                :warn="authWarn"
                @focus="onFocusAuthInput"
              />
              <div class="input__message--warn" v-if="authWarn">{{ authWarn }}</div>
              <div class="input__message--done" v-if="authDone">
                <div class="icon--done" />
                {{ authDone }}
              </div>
              <div class="input__extra">
                <div class="authCountDown" :class="{ warn: checkAuthCountDownIsWarn }" v-if="IsAuthCountDownIsShown">
                  {{ countDownTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article id="terms">
        <div class="term--all">
          <div class="term--all__header" @click="onClickAllTermAgree">
            <CheckBox :type="'block'" :checked="checkBothTermAgreed()" />
            <div class="term--all__header__title">아래 내용에 모두 동의합니다.</div>
          </div>
        </div>
        <hr />
        <div class="term--part">
          <div class="term--part__header">
            <CheckBox :type="'line'" :checked="term1IsChecked" @click="onClickTerm1Agree" />
            <div class="term--part__header__title" @click="onClickTerm1Agree">개인정보 수집 및 이용 동의</div>
            <div class="term--part__toggle__btn" @click="onClickTerm1ToggleBtn">
              {{ term1ToggleOpened ? '닫기' : '보기' }}
            </div>
          </div>
          <div class="term--part__toggle__content" v-if="term1ToggleOpened">
            개인 정보 처리 방침
            <br />
            주식회사 마켓디자이너스(이하 “회사“)는 링고라 서비스(이하 “서비스“)를 제공하면서 귀하의 개인정보보호를 매우
            중요시합니다.
            <br />
            회사는 『개인정보보호법』,『정보통신망 이용촉진 및 정보보호 등에 관한 법률』상의 개인정보보호규정 및
            정보통신부가 제정한 『개인정보보호지침』을 준수하고 있습니다. 개인정보보호정책을 통해 이용자가 제공하는
            개인정보가 어떠한 용도와 방식으로 이용되며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
            <br />
            이용자는 개인정보의 수집, 이용, 제공, 위탁과 관련하여 원하지 않는 경우 동의를 거부할 수 있습니다. 다만,
            이용자가 동의를 거부하는 경우 서비스의 전부 또는 일부를 이용할 수 없음을 알려드립니다.
            <br />
            <br />
            1.수집하는 개인정보 항목 및 수집방법
            <br />
            가. 수집하는 개인정보 항목
            <br />
            첫째, 회사는 회원가입 및 로그인, 원활한 고객상담, 각종 최적화된 서비스를 제공하기 위하여, 다음과 같은
            최소한의 개인정보를 이용자의 회원가입 시 수집하고 있습니다.
            <br />
            일반 회원가입 시
            <br />
            필수 항목: 아이디, 비밀번호, 이름, 성별, 생년월일, 본인인증, 이메일주소, 휴대폰 번호
            <br />
            소셜 네트워크 계정으로 회원가입 시
            <br />
            필수 항목: 아이디, 이름, 성별, 생년월일, 본인인증, 이메일주소, 휴대폰번호
            <br />
            둘째, 회사는 원활한 고객상담 및 각종 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
            <br />
            ■ 이름, 연락 가능 번호, 상담가능시간, 상담내용, 가입경로, 학습메일 수신 여부, 수강 정보 및 이벤트 소식 SMS
            수신 여부
            <br />
            셋째, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성, 수집될 수 있습니다.
            <br />
            ■ 모바일 서비스 이용 시 단말기에 관한 정보(단말기 모델, 하드웨어 ID, 운영체제 버전정보), 접속 IP 정보, 쿠키,
            결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록
            <br />
            넷째, 유료 서비스 이용 과정에서 아래와 같은 결제 정보가 수집될 수 있습니다.
            <br />
            ■ 신용카드 결제 시
            <br />
            필수항목: 카드사명, 카드번호 등
            <br />
            ■ 휴대전화 결제 시
            <br />
            필수항목: 휴대폰번호, 통신사, 결제승인번호 등
            <br />
            ■ 계좌 이체 시
            <br />
            필수항목: 은행명, 계좌번호 등
            <br />
            ■ 상품권 이용 시
            <br />
            필수항목: 상품권 번호
            <br />
            나. 개인정보 수집방법
            <br />
            회사는 다음과 같은 방법으로 개인정보를 수집합니다.
            <br />
            ■ 홈페이지, 모바일 애플리케이션(앱), 서면 양식, 팩스, 전화, 이메일, 이벤트 응모 등
            <br />
            <br />
            2.개인정보 이용목적
            <br />
            가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
            <br />
            콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 구매 및 요금 결제, 물품 배송 또는 청구서 등 발송, 요금 추심 등
            <br />
            나. 회원 관리
            <br />
            회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인 확인, 불량회원(링고라 이용약관 제 13조 위반 사유로
            인하여 영구이용이 정지된 회원)의 부정 이용방지와 비인가 사용방지, 가입 의사 확인, 가입 및 가입 횟수 제한,
            분쟁 조정을 위한 기록 보존, 민원처리, 공지사항 전달
            <br />
            다. 신규서비스 개발 및 마케팅에의 활용
            <br />
            신규 서비스 개발, 통계학적 특성, 이용 형태 등에 따른 맞춤형 서비스 제공, 광고 게재, 이벤트 참여기회 제공,
            접속빈도 파악, 회원의 서비스 이용에 대한 통계
            <br />
            3.개인정보의 제공 및 공유
            <br />
            회사는 이용자들의 개인정보를 위의 [1.수집하는 개인정보 항목 및 수집방법],[2.개인정보 이용목적]에서 고지한
            범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를
            외부에 공개하지 않습니다.
            <br />
            다만, 아래의 경우는 예외로 합니다.
            <br />
            ■ 고객님이 사전에 공개에 동의한 경우
            <br />
            ■ 법령의 규정에 따르거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
            <br />
            ■ 서비스 제공에 따른 요금 정산을 위하여 필요한 경우
            <br />
            ■ 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
            <br />
            <br />
            4.개인정보의 취급위탁
            <br />
            회사는 원활하고 편리한 서비스를 위하여, 최소한의 제한된 범위에서 개인정보를 외부 전문업체에 위탁하여
            운영하고 있습니다. 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 관련 사항들을 별도로 규정하고 있습니다.
            <br />
            가. 국내업체
            <br />
            &lt;결제&gt;
            <br />
            ■ 수탁업체
            <br />
            ◻︎ (주)LG유플러스
            <br />
            ◻︎ (주)KG이니시스
            <br />
            ◻︎ 네이버 주식회사
            <br />
            ◻︎ (주)카카오페이
            <br />
            ◻︎ (주)NHN한국사이버결제
            <br />
            ◻︎ (주)시옷
            <br />
            ■ 위탁업무: 결제, 구매 안전 서비스 제공
            <br />
            ■ 보유 및 이용 기간: 회원 탈퇴 및 위탁 계약 만료 시까지
            <br />
            &lt;배송&gt;
            <br />
            ◼︎ 수탁업체
            <br />
            ◻︎ 이루물류
            <br />
            ◻︎ 대화컴퓨터
            <br />
            ◻︎ (주)윈큐브마케팅
            <br />
            ◻︎ (주)텔콤
            <br />
            ◻︎ 반함 / 물류창고 배송
            <br />
            ◻︎ 쇼핑맛집
            <br />
            ◻︎ 한국후지필름 (주)
            <br />
            ◻︎ 원에듀피오디
            <br />
            ■ 위탁업무: 상품 배송
            <br />
            ■ 보유 및 이용 기간: 목적 달성 시 즉시 파기
            <br />
            &lt;인증&gt;
            <br />
            ◼︎ 수탁업체: NICE신용평가정보
            <br />
            ◼︎ 위탁업무: 본인인증
            <br />
            ◼︎ 보유 및 이용 기간: 목적 달성 시 즉시 파기
            <br />
            &lt;기술지원&gt;
            <br />
            ◼︎ 수탁업체
            <br />
            ◻︎ (주)리모트몬스터
            <br />
            ■ 위탁업무: RTC 기술 지원
            <br />
            ■ 보유 및 이용 기간: 회원 탈퇴 및 위탁 계약 만료 시까지
            <br />
            <br />
            5.개인정보의 보유 및 이용 기간
            <br />
            회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용 목적이 달성되거나
            보유 및 이용 기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 관련 법령에
            따라 명시한 기간에 별도로 분리 보관되며, 명시한 기간이 경과한 개인정보는 지체없이 파기합니다.
            <br />
            가. 회사 내부 방침에 의한 정보보유 사유
            <br />
            ◼︎ 회원 탈퇴 시 보존하는 개인 정보
            <br />
            ◻︎ 보존 항목: 이름, 이메일 주소, 연락처, 주소
            <br />
            ◻︎ 보존 근거: 명예훼손 등 권리침해 분쟁 및 수사협조
            <br />
            ◻︎ 보존 기간: 회원 탈퇴 후 1년
            <br />
            나. 관련 법령에 의한 정보보유 사유
            <br />
            ◼︎ 계약 또는 청약철회 등에 관한 기록
            <br />
            ◻︎ 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            ◻︎ 보존 기간 : 5년
            <br />
            ◼︎ 대금결제 및 재화 등의 공급에 관한 기록
            <br />
            ◻︎ 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            ◻︎ 보존 기간 : 5년
            <br />
            ◼︎ 소비자의 불만 또는 분쟁처리에 관한 기록
            <br />
            ◻︎ 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            ◻︎ 보존 기간 : 3년
            <br />
            ◼︎ 웹사이트 방문기록
            <br />
            ◻︎ 보존 근거 : 통신비밀보호법
            <br />
            ◻︎ 보존 기간 : 3개월
            <br />
            <br />
            6.개인정보 파기절차 및 방법
            <br />
            개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차
            및 방법은 다음과 같습니다.
            <br />
            가. 파기절차
            <br />
            ◼︎ 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보 보호
            사유에 따라(보유 및 이용 기간 참조) 일정 기간 저장된 후 파기됩니다.
            <br />
            ◼︎ 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
            <br />
            나. 파기방법
            <br />
            ◼︎ 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
            <br />
            ◼︎ 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
            <br />
            다. 휴면계정의 개인정보 파기절차
            <br />
            ◼︎ 링고라는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조 및 동법 시행령 제16조“에 따라 링고라
            서비스에 1년 이상 로그인하지 않은 계정은 개인정보보호를 위하여 해당 회원의 개인정보를 휴면계정으로 전환 및
            별도 관리합니다.
            <br />
            ◼︎ 휴면 처리되는 회원정보 : 회원 가입 시 또는 회원 정보 수정으로 수집/관리되는 모든 정보
            <br />
            ◼︎ 링고라는 휴면 계정의 개인정보 분리·저장 기간이 도래하기 1개월 전에 이메일 등을 통해 해당 이용자에게 관련
            내용을 공지합니다.
            <br />
            ◼︎ 휴면 처리된 계정의 개인정보는 분리 보관 시작으로부터 3년이 지나면 바로 파기합니다.
            <br />
            7.쿠키에 대한 운용 및 선택권
            <br />
            가. 쿠키의 운용 및 이용 목적
            <br />
            링고라는 일반적인 웹사이트와 마찬가지로 쿠키를 운용합니다. 쿠키는 웹사이트가 이용자의 인터넷 브라우저로
            보내는 작은 데이터 파일로써, 이용자의 컴퓨터 디스크에 저장됩니다. 쿠키는 이용자의 컴퓨터만을 식별하여,
            로그인 유지, 이용 형태 분석, 개인 맞춤형 서비스 제공 등 이용자에게 유용하고 편리한 서비스를 제공하는 데
            사용됩니다.
            <br />
            나. 쿠키의 설치, 운영과 거부
            <br />
            이용자는 쿠키 설치 및 사용에 대한 선택권이 있습니다. 웹 브라우저 종류에 따라 다소 다를 수 있지만, 대부분
            웹브라우저의 환경 설정을 통해 쿠키허용 여부를 결정하거나 기존의 쿠키 데이터 일체를 삭제할 수 있습니다. 다만
            쿠키의 저장을 거부할 경우, 로그인(Log in)이 필요한 일부 서비스의 이용에 제한이 생길 수 있습니다.
            <br />
            <br />
            8.이용자의 권리와 의무
            <br />
            가. 이용자의 권리
            <br />
            ◼︎ 회원은 언제든지 등록된 자신의 개인정보를 조회하거나 수정할 수 있으며 삭제나 가입 해지를 요청할 수
            있습니다.
            <br />
            ◼︎ 개인정보 조회, 수정을 위해서는 ‘내 정보 변경‘을, 가입해지(동의 철회)를 위해서는 ‘회원탈퇴‘를 클릭하여
            본인 확인 절차를 거친 후 열람/수정/탈퇴가 가능합니다.
            <br />
            ◼︎ 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
            <br />
            ◼︎ 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
            않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이
            통지하여 정정이 이루어지도록 하겠습니다.
            <br />
            나. 이용자의 의무
            <br />
            ◼︎ 회원은 본인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해주시기 바랍니다. 이용자가
            부정확하게 입력한 정보로 발생하는 사고의 책임은 이용자 자신에게 있으며 타인의 정보를 도용하거나 허위정보를
            입력할 경우 계정의 이용이 제한되거나 회원자격이 상실될 수 있습니다.
            <br />
            ◼︎ 회원은 스스로를 보호하고 타인의 정보를 침해하지 않을 의무를 가지고 있습니다. 비밀번호를 포함한 본인의
            개인정보가 유출되지 않도록 주의하시고 타인의 개인정보와 게시물을 훼손하지 않도록 유의해 주십시오.
            <br />
            ◼︎ 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시, ‘정보통신망이용촉진 및 정보
            보호등에관한법률’ 등에 의해 처벌받을 수 있습니다.
            <br />
            <br />
            9.개인정보의 기술적/관리적 보호 대책
            <br />
            회사는 이용자들의 개인정보를 처리하면서 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를
            위하여 다음과 같은 기술적/관리적 대책을 마련하고 있습니다.
            <br />
            가. 비밀번호 암호화
            <br />
            링고라 회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인
            및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
            <br />
            나. 해킹 등에 대비한 대책
            <br />
            회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고
            있습니다.
            <br />
            개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나
            자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를
            안전하게 전송할 수 있도록 하고 있습니다.
            <br />
            방화벽을 이용하여 외부로부터의 무단 접근을 방지하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한
            모든 기술적 장치를 갖추려 노력하고 있습니다.
            <br />
            다. 처리 직원의 최소화 및 교육
            <br />
            회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로
            갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
            <br />
            라. 개인정보 보호전담기구의 운영
            <br />
            사내 개인정보 보호전담기구를 통하여 개인정보 보호방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가
            발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의
            문제로 아이디(ID), 비밀번호, 이메일주소 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지
            않습니다.
            <br />
            <br />
            10.개인정보 보호책임자 및 담당자
            <br />
            가. 개인정보 관리 및 보호의 의무
            <br />
            회사는 개인 정보를 안전하게 이용하여 최상의 서비스를 제공하기 위하여 최선을 다하고 있습니다. 개인정보를
            보호하는데 있어, 상기 고지한 사항에 반하는 사고가 발생할 시에 개인정보 보호책임자가 책임을 집니다. 그러나
            기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한
            사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 일체 책임이 없습니다.
            <br />
            나. 담당부서 연락처
            <br />
            문의 : 고객센터 [1522-7802]
            <br />
            서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및
            담당부서로 연락해주시기 바랍니다. 링고라는 이용자의 목소리에 귀 기울이며, 신속하고 충분한 답변을 드릴 수
            있도록 하겠습니다.
            <br />
            &lt;개인정보 보호책임자 및 담당부서&gt;
            <br />
            책임자 : 조정현
            <br />
            담당부서 : 개인정보 보호팀
            <br />
            기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
            <br />
            ◼︎ 개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)
            <br />
            ◼︎ 대검찰청 사이버수사과 (spo.go.kr / 국번 없이 1301)
            <br />
            ◼︎ 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번 없이 182)
            <br />
            <br />
            11.고지의 의무
            <br />
            현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 ‘공지사항’을 통해
            고지할 것입니다. 또한 개인정보 보호정책에 버전번호 및 변경공고일자 및 그 시행일자 등을 부여하여 개정여부를
            쉽게 알 수 있도록 하고 있습니다.
            <br />
            <br />
            개인정보처리방침 버전번호 : 4.4
            <br />
            개인정보처리방침 변경 공고일자 : 2022년 4월 7일
            <br />
            변경 개인정보처리방침 시행일자 : 2022년 4월 7일
          </div>
        </div>
        <div class="term--part">
          <div class="term--part__header">
            <CheckBox :type="'line'" :checked="term2IsChecked" @click="onClickTerm2Agree" />
            <div class="term--part__header__title" @click="onClickTerm2Agree">
              할인 쿠폰 등 서비스 혜택 정보 수신 동의[선택]
            </div>
            <div class="term--part__toggle__btn" @click="onClickTerm2ToggleBtn">
              {{ term2ToggleOpened ? '닫기' : '보기' }}
            </div>
          </div>
          <div class="term--part__toggle__content" v-if="term2ToggleOpened">
            1. 개인정보의 수집 및 이용 목적
            <br />
            ① 무료 수업(레벨테스트) 제공
            <br />
            ② 학습전문가 1:1 컨설팅 제공
            <br />
            <br />
            2. 수집하는 개인정보 항목
            <br />
            ① 이름, 휴대폰번호, 설문답변
            <br />
            <br />
            3. 수집한 개인정보의 보유 및 이용기간
            <br />
            ① 보존기간 : 개인정보 보존 기간 만료 또는 동의 철회시까지
          </div>
        </div>
      </article>
      <button
        class="applySubmitBtn"
        :class="{ disabled: checkApplySubmitBtnDisabled }"
        :disabled="checkApplySubmitBtnDisabled"
        @click="onClickApplySubmitBtn"
      >
        무료 첫 수업 신청하기
      </button>
      <article id="tutorProcess">
        <label class="tutorProcess__label">
          누적 수업 수 500만 돌파!
          <br />
          180만 회원이 검증한 우수한 원어민 튜터풀!
          <br />
          소비자 선정 1:1 영어회화 부문 5년 연속 1위!
          <br />
          <br />
          링고라는 이렇게 달라요.
        </label>
        <div class="tutorProcess__cards--web">
          <div class="tutorProcess__card" v-for="process in tutorProcessList" :key="process.id">
            <img :src="process.imgUrl" />
            <div class="tutorProcess__card__textWrapper">
              <div class="title" v-html="process.title"></div>
              <div class="desc">{{ process.desc }}</div>
            </div>
          </div>
        </div>

        <swiper
          :slides-per-view="1"
          :space-between="10"
          :initialSlide="0"
          :watchOverflow="true"
          :centered-slides="true"
          class="tutorProcess__cards--mob"
        >
          <swiper-slide class="tutorProcess__card" v-for="process in tutorProcessList" :key="process.id">
            <img :src="process.imgUrl" />
            <div class="tutorProcess__card__textWrapper">
              <div class="title" v-html="process.title"></div>
              <div class="desc">{{ process.desc }}</div>
            </div>
          </swiper-slide>
        </swiper>

        <!-- <swiper
          :slides-per-view="1"
          :space-between="10"
          :initialSlide="0"
          :watchOverflow="true"
          :centered-slides="true"
          class="tutorProcess__cards--mob"
        >
          <swiper-slide class="tutorProcess__card" v-for="process in tutorProcessList" :key="process.id">
            <img :src="process.imgUrl" />
            <div class="tutorProcess__card__textWrapper">
              <div class="title" v-html="process.title"></div>
              <div class="desc">{{ process.desc }}</div>
            </div>
          </swiper-slide>
        </swiper> -->

        <div class="linkForTutorPage" @click="onClickLinkForTutorPage">
          링고라 다운로드하러 가기
          <img src="https://cdn.tutoring.co.kr/uploads/home/img/leveltest/ic_arrow_applylt.png" />
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import api from '@/common/api';
import Input from './components/Input.vue';
import CheckBox from './components/CheckBox.vue';

const COUNT_DOWN_TIMER = 3 * 60;
const descListInitArr = [
  {
    id: 1,
    text: '교육 전문 원어민 튜터와 15분 1:1 회화',
  },
  { id: 2, text: '체계적인 레벨 <br/>분석 리포트' },
  { id: 3, text: '전문 컨설턴트의 1:1 맞춤 컨설팅' },
];
const purposeListInitArr = [
  {
    id: 1,
    name: '커리어 개발',
    checked: false,
  },
  {
    id: 2,
    name: '해외여행 / 유학 / 취업',
    checked: false,
  },
  {
    id: 3,
    name: '비즈니스 영어 회화',
    checked: false,
  },
  {
    id: 4,
    name: '자격증 취득',
    checked: false,
  },
  {
    id: 5,
    name: '프리토킹 및 자기 계발',
    checked: false,
  },
  {
    id: 6,
    name: '기타',
    checked: false,
  },
];
const levelListInitArr = [
  {
    id: 1,
    name: '몇 가지 단어로만 말할 수 있어요.',
    checked: false,
  },
  {
    id: 2,
    name: '짧은 대화를 할 수 있어요.',
    checked: false,
  },
  {
    id: 3,
    name: '프리토킹이 가능해요.',
    checked: false,
  },
];
const tutorProcessListInitArr = [
  {
    id: 1,
    imgUrl: 'https://res.tutoring.co.kr/res/home/img/leveltest/img_feat_01.png ',
    title: '고퀄리티 원어민 회화',
    desc: '국내 최대 규모의 교육 전문 튜터를 보유하고 있어요. 튜터들이 1:1 피드백을 제공하며 학습자의 영어를 완벽 케어합니다.',
  },
  {
    id: 2,
    imgUrl: 'https://res.tutoring.co.kr/res/home/img/leveltest/img_feat_02.png ',
    title: '365일 업계최저가 보장',
    desc: '기술 혁신을 바탕으로 최고의 학습 경험을 가장 경제적인 비용으로 제공해요. 경쟁사 가격 모니터링을 통해 상시 업계 최저가를 보장해요!',
  },
  {
    id: 3,
    imgUrl: 'https://res.tutoring.co.kr/res/home/img/leveltest/img_feat_03.png ',
    title: '국내 유일!<br/>예약 없이 바로 수업 가능',
    desc: '원하는 튜터, 토픽을 직접 선택해 24시간 중 원하는 시간에 예약 없이 바로 수업이 가능해요. ',
  },
  {
    id: 4,
    imgUrl: 'https://res.tutoring.co.kr/res/home/img/leveltest/img_feat_04.png ',
    title: '직접 고르는<br/>900개의 수업 토픽',
    desc: '일상회화부터 비즈니스, 어학 자격증까지! 원하는 수업 토픽을 직접 선택해 학습할 수 있어요.',
  },
  {
    id: 5,
    imgUrl: 'https://res.tutoring.co.kr/res/home/img/leveltest/img_feat_05.png ',
    title: 'AI 튜터와의 시너지',
    desc: '같은 교재로 AI 튜터와 먼저 학습할 수 있으니까! 원어민과의 수업도 긴장 없이 자신감 UP, 학습 효과 UP!',
  },
];
export default {
  name: 'ClassApply',
  components: {
    Input,
    Swiper,
    SwiperSlide,
    CheckBox,
  },
  data() {
    return {
      isB2bInfoTooltipOpen: false,
      isB2BInfoTooltipClicked: false,
      descList: descListInitArr,
      purposeList: purposeListInitArr,
      levelList: levelListInitArr,
      tutorProcessList: tutorProcessListInitArr,
      /* 신청자 정보 입력 데이터 */
      name: '',
      phone: '',
      auth: '',
      /*--------------------*/
      /* 신청자 정보 입력 에러메세지 */
      nameWarn: null,
      phoneWarn: null,
      authWarn: null,
      /*----------------------*/
      /* 핸드폰 인증관련 상태값 */
      isActiveAuthBtn: false,
      isRetryAuthBtn: false,
      isAuthBtnDisabled: true,
      authCountDown: -1,
      authTimer: null,
      authDone: null,
      /*-------------------*/
      /* 약관 동의 관련 */
      term1IsChecked: false,
      term2IsChecked: false,
      term1ToggleOpened: false,
      term2ToggleOpened: false,
      /*-------------*/
      guestKey: null,
      isApplySubmitDoneModalOpen: false,
      isApplySubmitFailModalOpen: false,
    };
  },
  watch: {
    authCountDown(val) {
      if (val === COUNT_DOWN_TIMER) {
        this.authTimer = setInterval(this.countDown, 1000);
        this.isAuthBtnDisabled = true;
        return;
      }
      if (val < 0) {
        clearInterval(this.authTimer);
        this.authTimer = null;
        this.isRetryAuthBtn = true;
        this.isAuthBtnDisabled = false;
      }
    },
    phone(val) {
      if (val.length < 11) {
        this.isAuthBtnDisabled = true;
        return;
      }
      this.isAuthBtnDisabled = false;
    },
  },
  unmounted() {
    clearInterval(this.authTimer);
    this.authTimer = null;
  },
  computed: {
    checkAuthCountDownIsWarn() {
      return this.authCountDown < 60;
    },
    IsAuthCountDownIsShown() {
      return this.authCountDown > -1;
    },
    countDownTime() {
      const min = parseInt(Number(this.authCountDown) / 60, 10);
      const sec = Number(this.authCountDown) % 60;

      const minStr = this.changeTimeForm(min);
      const secStr = this.changeTimeForm(sec);
      return `${minStr}분 ${secStr}초`;
    },
    checkApplySubmitBtnDisabled() {
      if (!this.checkIsPurpose()) {
        return true;
      }
      if (this.name.length === 0) {
        return true;
      }
      if (!this.authDone) {
        return true;
      }
      if (!this.term1IsChecked) {
        return true;
      }
      return false;
    },
  },
  methods: {
    moveToB2bProgram() {
      this.$router.push({
        path: `/b2bProgram`,
      });
    },
    handleB2bInfoTooltipClick() {
      this.isB2BInfoTooltipClicked = !this.isB2BInfoTooltipClicked;
    },
    setGuestKey(key) {
      this.guestKey = key;
    },
    getNewLevelList(id) {
      return this.levelList.map((level) => {
        const newLevel = { ...level };
        newLevel.checked = false;
        if (level.id === id) {
          newLevel.checked = !level.checked;
        }
        return newLevel;
      });
    },
    getNewPurposeList(id) {
      return this.purposeList.map((purpose) => {
        const newPurpose = { ...purpose };
        newPurpose.checked = false;
        if (purpose.id === id) {
          newPurpose.checked = !purpose.checked;
        }
        return newPurpose;
      });
    },
    onClickPurposeBtn(id) {
      this.purposeList = this.getNewPurposeList(id);
    },
    onClickLevelBtn(id) {
      this.levelList = this.getNewLevelList(id);
    },
    onClickLinkForTutorPage() {
      window.location.href = `https://abr.ge/nah3mk`;
    },
    /* 신청자정보입력 인풋창 핸들링 관련 메소드 */
    onChangeName(event) {
      if (!event?.target) {
        return;
      }
      this.name = event.target.value;
    },
    onChangePhone(event) {
      const regex = /[^0-9]/g;
      if (!event?.target) {
        return;
      }
      this.phone = event.target.value.replace(regex, '');
    },
    onChangeAuth(event) {
      if (!event?.target) {
        return;
      }
      this.auth = event?.target.value;
    },
    onBlurNameInput() {
      if (this.name.length > 0) {
        this.nameWarn = null;
        return;
      }
      this.nameWarn = '이름을 입력해주세요.';
    },
    onBlurPhoneInput() {
      if (this.phone.length < 11) {
        this.phoneWarn = '휴대폰 번호를 정확하게 입력해주세요.';
        return;
      }
      this.phoneWarn = null;
    },
    onBlurAuthInput() {
      if (this.auth.length < 4) {
        this.authWarn = '인증번호를 정확하게 입력해주세요.';
        return;
      }
      this.confirmAuth();
      this.authWarn = null;
    },
    onFocusNameInput() {
      this.nameWarn = null;
    },
    onFocusPhoneInput() {
      this.phoneWarn = null;
    },
    onFocusAuthInput() {
      this.authWarn = null;
    },
    /*--------------------------------*/
    /* 핸드폰 인증 관련 메소드 */
    startAuthCountDown() {
      this.authCountDown = COUNT_DOWN_TIMER;
    },
    async requestAuth() {
      const userKey = localStorage.getItem('key');
      this.auth = '';
      this.authDone = null;
      const OS_WEB = 0;
      const response = await api.post('/app/users/setUserGuestContactPhone', {
        phone: this.phone,
        os: OS_WEB,
        key: userKey || 0,
      });
      const { key } = response.data;
      this.setGuestKey(key);
      this.startAuthCountDown();
    },
    countDown() {
      this.authCountDown -= 1;
    },
    changeTimeForm(time) {
      if (time > 9) {
        return `${time}`;
      }
      if (time > 0) {
        return `0${time}`;
      }
      return '00';
    },
    onClickRequestAuthBtn() {
      this.requestAuth();
    },
    finishAuth() {
      clearInterval(this.authTimer);
      this.authWarn = null;
      this.authTimer = null;
      this.authCountDown = -1;
      this.authDone = '인증이 완료되었습니다.';
    },
    async confirmAuth() {
      const response = await api.post('/app/users/setUserContactPhoneConfirm', {
        phone: this.phone,
        code: this.auth,
        key: this.guestKey,
      });
      const { result } = response;
      if (Number(result) === 2000) {
        this.finishAuth();
        return;
      }
      this.authWarn = '인증번호를 정확하게 입력해주세요.';
    },
    /*-------------------*/
    /* 약관동의 관련 메소드 */
    onClickAllTermAgree() {
      if (this.checkBothTermAgreed()) {
        this.term1IsChecked = false;
        this.term2IsChecked = false;
        return;
      }
      this.term1IsChecked = true;
      this.term2IsChecked = true;
    },
    checkBothTermAgreed() {
      return this.term1IsChecked && this.term2IsChecked;
    },
    onClickTerm1Agree() {
      this.term1IsChecked = !this.term1IsChecked;
    },
    onClickTerm2Agree() {
      this.term2IsChecked = !this.term2IsChecked;
    },
    onClickTerm1ToggleBtn() {
      this.term1ToggleOpened = !this.term1ToggleOpened;
    },
    onClickTerm2ToggleBtn() {
      this.term2ToggleOpened = !this.term2ToggleOpened;
    },
    /*------------------*/
    /* 신청 폼 Submit 관련 메소드 */
    checkIsPurpose() {
      return this.purposeList.filter((purpose) => purpose.checked).length > 0;
    },
    getSelectedPurpose() {
      return this.purposeList.filter((purpose) => purpose.checked)[0].name;
    },
    async applySubmit() {
      const response = await api.post('/open/app/consulting/request', {
        name: this.name,
        phone: this.phone,
        key: this.guestKey,
        forms_value: this.getSelectedPurpose(),
        agree_value: `${Number(this.term1IsChecked)},${Number(this.term2IsChecked)}`,
      });
      const { result } = response;
      if (Number(result) === 2000) {
        this.openApplySubmitDoneModal();
      } else if (Number(result) === 1000) {
        this.openApplySubmitFailModal();
      }
    },
    onClickApplySubmitBtn() {
      this.applySubmit();
    },
    openApplySubmitDoneModal() {
      this.isApplySubmitDoneModalOpen = true;
    },
    openApplySubmitFailModal() {
      this.isApplySubmitFailModalOpen = true;
    },
    closeApplySubmitDoneModal() {
      this.isApplySubmitDoneModalOpen = false;
      window.location.href = `${window.location.protocol}//${window.location.host}/v2`;
    },
    closeApplySubmitFailModal() {
      this.isApplySubmitFailModalOpen = false;
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
#tutoringContent {
  overflow: hidden;
}
.inner {
  margin: 0 auto;
  max-width: 450px;
  padding: 0 24px;
  word-break: keep-all;
}

#classApplyHeader {
  margin-top: 60px;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px; /* 141.667% */
  color: #121214;
}

.classApply-header-desc {
  color: var(--Opacity-Black600, rgba(18, 18, 20, 0.6));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: 8px;
}

#description {
  margin-top: 40px;

  .description__card {
    padding: 16px;
    min-height: none;
    box-sizing: border-box;
    &__list {
      display: flex;
      gap: 8px;
    }
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 110px;
      background: #662ddf;
      border-radius: 20px;

      &__desc {
        margin-top: 8px;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.31;
        color: #ffffff;
        text-align: center;
        opacity: 0.7;
      }
      &__icon--check {
        width: 24px;
      }
    }
  }

  .description__desc {
    margin-top: 12px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.69;
    color: #85808d;
  }
}

#checkLevel {
  margin-top: 40px;

  .checkLevel__label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.38;
    letter-spacing: -0.2px;
    color: #121214;
  }

  .checkLevel__levels {
    margin-top: 12px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      border: 1px solid #d6d3dd;
      border-radius: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.38;
      letter-spacing: -0.2px;
      color: #12121499;
    }
    button.checked {
      border: 1px solid #5d04d9;
      color: #5d04d9;
    }
    button + button {
      margin-top: 6px;
    }
  }
}

#applierInfo {
  margin-top: 50px;

  .applierInfo__label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.38;
    letter-spacing: -0.2px;
    color: #121214;
  }

  .triangle {
    display: inline-block;
    border-style: solid;
    border-width: 0 3.75px 6.5px 3.75px;
    border-color: transparent transparent #3f3d44 transparent;
    position: absolute;
    top: -7px;
    right: 10px;
    z-index: 10;
  }

  .tooltip {
    max-width: 312px;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 25px;
    left: 0px;
    padding: 16px 8px;
    border-radius: var(--Radius-m, 8px);
    background: var(--Gray-800, #3f3d44);

    li {
      color: var(--Opacity-White600, rgba(255, 255, 255, 0.6));
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      padding-left: 16px; /* disc와 텍스트 사이 간격 설정 */

      &::before {
        content: '•'; /* 또는 '\2022' */
        position: absolute;
        left: 14px; /* disc 위치 조정 */
        color: var(--Opacity-White600, rgba(255, 255, 255, 0.6));
      }
      span {
        cursor: pointer;
        color: var(--Opacity-White, #fff);
        text-decoration-line: underline;
        text-decoration-style: solid;
      }
    }
  }

  .applierInfo__b2b__info__wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 4px;
    position: relative;
  }

  .applierInfo__b2b__info {
    color: var(--Primary-500, #662ddf);
    font-size: 12px;
    font-weight: 700;
    line-height: 18px; /* 150% */
  }

  .applierInfo__b2b__info__icon {
    cursor: pointer;
  }

  .applierInfo__form {
    margin-top: 12px;
    &__block {
      display: flex;
      margin-top: 10px;
    }
    .input__wrapper {
      flex: 1;

      .input__extra {
        position: absolute;
        right: 16px;
        top: 15px;

        .authCountDown {
          font-weight: 700;
          font-size: 11px;
          line-height: 1.27;
          color: #5e5a66;
        }
        .authCountDown.warn {
          color: #f24b4b;
        }
      }

      .input__message--warn {
        margin-top: 6px;
        font-weight: 400;
        font-size: 11px;
        line-height: 1.27;
        color: #f24b4b;
      }
      .input__message--done {
        display: flex;
        align-items: center;
        margin-top: 6px;
        font-weight: 400;
        font-size: 11px;
        line-height: 1.27;
        color: #5d04d9;

        .icon--done {
          position: relative;
          display: inline-block;
          width: 11px;
          height: 11px;
          justify-content: center;
          margin-right: 4px;

          // check icon
          &::after {
            display: block;
            position: absolute;
            left: 14%;
            top: 12%;
            width: 7px;
            height: 4px;
            border: 2px solid #5d04d9;
            border-top: 0;
            border-right: 0;
            transform: rotate(-45deg);
            content: '';
          }
        }
      }
    }
    .requestAuthBtn {
      height: 44px;
      margin-left: 10px;
      padding: 0 16px;
      border: 1px solid #5d04d9;
      color: #5d04d9;
      border-radius: 8px;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.38;
    }
    .requestAuthBtn.disabled {
      border: 1px solid #d6d3dd;
      color: #5e5a66;
    }
  }
}

@media (max-width: 360px) {
  #description {
    .description__card {
      height: auto;
    }
  }

  #applierInfo {
    .tooltip {
      top: 35px;
    }
  }
}

#terms {
  margin-top: 52px;

  .term--all {
    &__header {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__title {
        flex: 1;
        margin-left: 9px;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.38;
        letter-spacing: -0.2px;
        color: #121214;
      }
    }
  }

  hr {
    border: 0.5px solid #e8e6ed;
    margin: 18px 0;
  }

  .term--part {
    &__header {
      display: flex;
      align-items: center;

      &__title {
        flex: 1;
        margin-left: 9px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: -0.2px;
        color: #3f3d44;
        cursor: pointer;
      }
    }
    &__toggle__btn {
      text-decoration: underline;
      font-weight: 400;
      font-size: 11px;
      line-height: 1.27;
      text-align: center;
      color: #85808d;
      cursor: pointer;
    }
    &__toggle__content {
      box-sizing: border-box;
      max-height: 170px;
      margin-top: 7px;
      font-weight: 400;
      font-size: 11px;
      line-height: 1.27;
      color: #85808d;
      overflow-y: scroll;

      border: 1px solid #f1f0f5;
      border-radius: 10px;
      padding: 15px 0 15px 15px;
    }
    &__toggle__content::-webkit-scrollbar {
      display: block;
      width: 15px;
    }
    &__toggle__content::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      background-color: #e8e6ed;
      border-radius: 2px;
    }
  }
  .term--part + .term--part {
    margin-top: 8px;
  }
}

.applySubmitBtn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #5d04d9;
  border-radius: 10px;

  font-weight: 700;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.5px;
  color: #ffffff;

  &.disabled {
    background: #afaab7;
    color: #fff;
  }
}

#tutorProcess {
  margin-top: 40px;
  .tutorProcess__label {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #121214;
  }
  .tutorProcess__cards {
    &--web,
    &--mob {
      margin-top: 40px;
      .tutorProcess__card {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e8e6ed;
        border-radius: 20px;
        padding: 32px;
        gap: 8px;
        img {
          width: 100px;
          height: auto;
        }
        .tutorProcess__card__textWrapper {
          width: 200px;
          .title {
            color: var(--Opacity-Black, #121214);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px; /* 150% */
          }
          .desc {
            color: var(--Opacity-Black600, rgba(18, 18, 20, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
    @media (max-width: 768px) {
      &--mob {
        display: block;
      }
      &--web {
        display: none;
      }
    }
    @media (min-width: 768px) {
      &--mob {
        display: none !important;
      }
      &--web {
        display: block;
      }
    }
  }

  .tutorProcess__cards--web {
    .tutorProcess__card {
      /* width: 100%; */
    }
    .tutorProcess__card + .tutorProcess__card {
      margin-top: 10px;
    }
  }

  .tutorProcess__cards--mob {
    .tutorProcess__card {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e8e6ed;
      border-radius: 20px;
      padding: 32px;
      gap: 8px;
      min-height: 200px;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 100px;
        height: auto;
      }
    }
  }

  @media (max-width: 360px) {
    .tutorProcess__cards--mob {
      .tutorProcess__card {
        padding: 12px;
      }
    }
  }

  .linkForTutorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 30px auto 100px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #121214;
    img {
      margin-top: 10px;
      width: 34px;
    }
  }
}
</style>
